import React from 'react';
import './App.css';
import Header from './components/Header';
import AboutContactContainer from './containers/AboutContact';
import ProjectsBanner from './components/ProjectsBanner';
import ProjectsContainer from './containers/ProjectsContainer';

const App = () => {
    const projects = [
        // new projects here[title, repo_url, description for overlay, image src, live site link]
        ["BoardGameGeek Collection Viewer", "https://github.com/paulmcmanus2021/bgg_viewer", "Multi-page app to fetch and render a carousel with user's collection. Each individual carousel item shows play-count and owned/wishlist status.", "/images/bgg-viewer.png",""],
        ["DuelScorer", "https://github.com/paulmcmanus2021/DuelScorer","First React Native project. 7 Wonders Duel board game scoring app.", "/images/EmulatorOnly.png", ""],
        ["Activity Feed", "https://github.com/paulmcmanus2021/BetaSoftProject","Learning responsive design, toggle and pagination. Data from jsonplaceholder. Built with React.", "/images/activityfeed.png", ""],
        ["The Thing","https://github.com/paulmcmanus2021/The_Thing","CodeClan group project. Digital version of a board game. Built with React/ Spring.","/images/thething.png", ""],
        ["CO2ByYou","https://github.com/paulmcmanus2021/CO2_Footprint_Calculator","CodeClan group project. Web app that calculates a user’s CO2 footprint based on their lifestyle. Built with M.E.V.N.","/images/co2byu.png", ""],
        ["Where To","https://github.com/paulmcmanus2021/travel_bucket_list","CodeClan solo project. Web app to track user’s travel adventures. Built with Ruby and Sinatra. Not mobile friendly.","/images/whereto.png", ""],
]
  
    return (
      <div className="App">
        <Header />
        <AboutContactContainer />
        <ProjectsBanner />
        <ProjectsContainer projects={projects} />
      </div>
    );
}

export default App;