import React from 'react';
import Project from '../components/Project'

function ProjectsContainer(props){
    const projectList = props.projects.map((project, index) => {
        return (
          <Project title={project[0]} repo={project[1]} description={project[2]} image={project[3]} site={project[4]} key={index}></Project>
        )
      })

    return(
        <div className="projects-container">
            <ul className="project-grid">
                {projectList}
            </ul>
        </div>
    )}
 
export default ProjectsContainer;