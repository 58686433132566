import React from 'react';

function Github(props){
    return(
        <div className="github">
            <a href="https://github.com/paulmcmanus2021" target="_blank" rel="noopener noreferrer">
            <img className="contact-link" src="/images/github.svg"  height="24" weight="24" alt="github-icon">
            </img>
            <p>Github</p>
            </a>
        </div>
    )
}

export default Github;