import React from 'react';
import About from '../components/About';
import ContactContainer from '../containers/ContactContainer';

function AboutContactContainer(props){
    return(
        <div className="about-contact-container">
           <About />
           <ContactContainer />
        </div>
    )
}

export default AboutContactContainer;