import React from 'react'

const Project = (props) => {
    if (props.site.length > 1) {
        return (
            <div className="single-project">
            <div className="project-item">
                <div className="image-container"></div>
                    <img className="project-image" src={props.image} alt={props.description}></img>
                    <div className="overlay">
                    <div className="text">{props.description}</div>
                </div>
            </div>
            <div className="project-title">
                <a href={props.repo} target="_blank" rel="noopener noreferrer">{props.title}</a>
                <a href={props.site} target="_blank" rel="noopener noreferrer">Site</a>
            </div>
        </div>
        );
    } else {
        return (
        <div className="single-project">
            <div className="project-item">
                <div className="image-container"></div>
                    <img className="project-image" src={props.image} alt={props.description}></img>
                    <div className="overlay">
                    <div className="text">{props.description}</div>
                </div>
            </div>
            <a className="project-title" href={props.repo} target="_blank" rel="noopener noreferrer">{props.title}</a>
        </div>
        )
    }
}

export default Project 