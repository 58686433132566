import React from 'react';
import Github from '../components/contactlinks/Github';
import LinkedIn from '../components/contactlinks/LinkedIn';
import Email from '../components/contactlinks/Email';

function ContactContainer(props){
    return(
        <div className="contact-container">
            <h2><u>Contact</u></h2>
            <div className="contact-links">
                <LinkedIn />
                <Github />
                <Email />
            </div>
        </div>
    )
}

export default ContactContainer;