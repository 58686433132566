import React from 'react';

function Email(props){
    return( 
        <div className="email">
            <a href="mailto:pmcmanus2021@gmail.com" target="_blank" rel="noopener noreferrer">
            <img className="contact-link" src="/images/gmail.png"   height="24" weight="24" alt="email-icon">
            </img>
            <p>Email</p>
            </a>
        </div>
    )
}

export default Email;