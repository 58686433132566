import React from 'react'

const ProjectsBanner = () => {
    return(
        <div>
            <h1><u>Projects</u></h1>
            <h2>Hover on image for description</h2>
        </div>
    )
}

export default ProjectsBanner;