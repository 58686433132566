import React from 'react';

function Contact(props){
    return(
        <div className="linkedin">
            <a href="https://www.linkedin.com/in/paulmcmanusdev/" target="_blank" rel="noopener noreferrer"> 
            <img className="contact-link" src="/images/linkedin.svg"
             height="24" weight="24" alt="linkedin-icon">
            </img>
            <p>LinkedIn</p>
            </a>
        </div>
    )
}

export default Contact;