import React from 'react';

function Header(props){
    return(
        <div className="header">
            <h1 id="name">Paul McManus</h1>
            <h2 id="title">Software Developer & Test Engineer</h2>
        </div>
    )
}

export default Header;